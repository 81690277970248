import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptorService } from '@services/auth-interceptor.service';
import { BrowserstateInterceptor } from './interceptors/browserstate.interceptor';
import { APP_BASE_HREF, DATE_PIPE_DEFAULT_OPTIONS, PlatformLocation, registerLocaleData } from '@angular/common';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { SharedModule } from '@shared/shared.module';

import localePt from '@angular/common/locales/pt';
import { provideNgxMask } from 'ngx-mask';
import { provideNativeDateAdapter } from '@angular/material/core';
import {NgChartsModule} from "ng2-charts";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { environment } from '@env/environment';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha-2';

registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RecaptchaModule,
    AppRoutingModule,
		AkitaNgRouterStoreModule,
    BrowserAnimationsModule,
    RouterModule,
    NgChartsModule,
    NgxSkeletonLoaderModule,
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass :'toast-top-right'
    }),
  ],
  providers: [
		{ provide: LOCALE_ID, useValue: 'pt-BR' },
		{ provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: BrowserstateInterceptor, multi: true },
		{
			provide: APP_BASE_HREF,
			useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
			deps: [PlatformLocation]
		},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        subscriptSizing: 'dynamic',
      }
    },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: {timezone: '-0300'}
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.googleRecaptchaSiteKey } as RecaptchaSettings,
    },
    provideNativeDateAdapter(),
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function getLocalStorage() {
	return typeof window !== 'undefined' ? window.localStorage : null;
}

export function parseLangFromHref(): string | null {
  const url = new URL(window.location.href);
  const langParam = url.searchParams.get("lang");

  // Retorna o idioma ou null se não houver parâmetro "lang" na URL
  return langParam ? langParam : null;
}
