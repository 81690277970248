import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Router, Routes } from '@angular/router';
import { environment } from '@env/environment';
import { isLoggedGuard } from './guards/is-logged.guard';
import { hasSessionGuard } from './guards/has-session.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./views/public/public.module').then(m => m.PublicModule),
    // canActivate: ['AuthGuard']
  },
  {
    path: 'painel',
    loadChildren: () => import('./views/private/private.module').then(m => m.PrivateModule),
    canActivate: [hasSessionGuard]
  },
  {
		path: '**',
		redirectTo: environment.home
	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  // providers: [
  //   isLoggedGuard
  // ]
})
export class AppRoutingModule { }
