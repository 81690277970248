import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { SidebarService } from '@services/sidebar.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {

  constructor(
    private readonly titleService: Title,
  ) {}

	ngOnInit(): void {
    this.titleService.setTitle(environment.appName);
	}
}
